@import 'tailwind';
@import 'fonts';

html, body {
	background-color: #fff;
	color: #636b6f;
	font-family: 'Cooper Hewitt', 'Nunito', sans-serif;
	height: 100vh;
	margin: 0;
	text-rendering: geometricPrecision;
	-webkit-font-smoothing: antialiased;
	font-smooth: antialiased;
	-webkit-text-stroke: 0.45px !important;
}

html.sr .load-hidden {
	visibility: hidden;
}

#root {
	height: 100%;
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
    opacity: 0;
}